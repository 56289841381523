<ae2-unit-list-container>
  <ng-template let-results="results">
    <aside class="unit" *ngIf="results.length > 0">
      <div *ngFor="let item of results; let i = index"> 

        <div class="unit_container" fxLayout fxLayout.xs="column" fxLayout.sm="column">
          <div class="unit_container_item" fxFlex="40%" fxFlex.xs="auto">
            <div class="unit_container_item_image" *ngIf="item.largeImage" style.background-image="url({{ item.largeImage | ae2Thumbor:'700x'}})"></div>
            <div class="unit_container_item_image" *ngIf="!item.largeImage" [ngStyle]="{'background': 'url(/assets/images/fullback.jpg)'}"></div>
          </div>
          <div class="unit_container_item" fxFlex="60%" fxFlex.xs="auto">
            <h2 class="unit-header" [innerHTML]="item.name"></h2>
            <div class="unit_container_item_description" [innerHTML]="item.description"></div>
          </div>
        </div>
      </div>
    </aside>
  </ng-template> 
</ae2-unit-list-container>
