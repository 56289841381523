<aside class="ev">
  <section class="ev_ct" fxLayout fxLayout.xs="column" fxLayout.sm="column" fxLayoutAlign="center center">
    <div class="ev_ct_b" fxFlex="calc(50% - 15px)" fxFlex.xs="auto" fxFlex.sm="auto" *ngFor="let activity of results">

      <ae2-event-list-container sort="start_time" pageSize="4" nextEvents="true" [activityList]="[activity.id]"
        [useLazyLoading]="true" [useLoadAllPages]="false">
        <ng-template let-events="results" let-autoBooks="eventAutoBooks" let-books="eventBooks">
          <ng-container *ngIf="events.length > 0 && activity.name">
            <h2 class="ev_ct_tt">PRÓXIMAS AULAS DE {{activity.name}}</h2>
          </ng-container>
          <ng-container *ngIf="events.length > 0">
            <div fxLayout="row wrap" fxLayout.xs="column wrap" fxLayoutAlign="center center">
              <ng-container *ngFor="let event of events">

                <ae2-event-widget [event]="event" [autoBooks]="autoBooks" [eventBooks]="books" fxFlex="calc(50% -15px)"
                fxFlex.xs="auto" fxFlex.sm="25%">
                </ae2-event-widget>

              </ng-container>
            </div>
          </ng-container>

        </ng-template>
      </ae2-event-list-container>

    </div>
  </section>
</aside>