/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./nex-events.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angularecommerce/core/components/event-widget/event.component.ngfactory";
import * as i3 from "@angular/flex-layout/flex";
import * as i4 from "@angular/flex-layout/core";
import * as i5 from "@angularecommerce/core/components/event-widget/event.component";
import * as i6 from "@angularecommerce/core/services/booking-manager/booking-manager.service";
import * as i7 from "@angularecommerce/core/services/http-response-messages/http-response-messages.service";
import * as i8 from "@angular/router";
import * as i9 from "@angularecommerce/core/services/events/events.service";
import * as i10 from "@angularecommerce/core/services/event-auto-books/event-auto-books.service";
import * as i11 from "@angularecommerce/core/services/dialog/dialog.service";
import * as i12 from "@angularecommerce/core/services/event-templates/event-templates.service";
import * as i13 from "@angularecommerce/core/services/feedback/feedback.service";
import * as i14 from "@angularecommerce/core/services/auth-store/auth-store.service";
import * as i15 from "@angularecommerce/core/services/login-strategy/login-strategy";
import * as i16 from "angular-l10n";
import * as i17 from "@ngrx/store";
import * as i18 from "@angularecommerce/core/services/settings/settings.service";
import * as i19 from "@angular/common";
import * as i20 from "../../../../../node_modules/@angularecommerce/core/components/event-list-container/event-list-container.component.ngfactory";
import * as i21 from "@angularecommerce/core/classes/list-container-base/list-container-base";
import * as i22 from "@angularecommerce/core/components/event-list-container/event-list-container.component";
import * as i23 from "@angularecommerce/core/services/event-books/event-books.service";
import * as i24 from "./nex-events.component";
var styles_NexEventsComponent = [i0.styles];
var RenderType_NexEventsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NexEventsComponent, data: {} });
export { RenderType_NexEventsComponent as RenderType_NexEventsComponent };
function View_NexEventsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [["class", "ev_ct_tt"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["PR\u00D3XIMAS AULAS DE ", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.name; _ck(_v, 2, 0, currVal_0); }); }
function View_NexEventsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "ae2-event-widget", [["fxFlex", "calc(50% -15px)"], ["fxFlex.sm", "25%"], ["fxFlex.xs", "auto"]], null, null, null, i2.View_Ae2EventWidgetComponent_0, i2.RenderType_Ae2EventWidgetComponent)), i1.ɵdid(2, 737280, null, 0, i3.FlexDirective, [i4.MediaMonitor, i1.ElementRef, [3, i3.LayoutDirective], i4.StyleUtils, i4.LAYOUT_CONFIG], { flex: [0, "flex"], flexXs: [1, "flexXs"], flexSm: [2, "flexSm"] }, null), i1.ɵdid(3, 245760, null, 0, i5.Ae2EventWidgetComponent, [i6.Ae2BookingManagerService, i7.Ae2HttpResponseMessagesService, i8.Router, i9.Ae2EventsService, i10.Ae2EventAutoBooksService, i11.Ae2DialogService, i1.ChangeDetectorRef, i12.Ae2EventTemplatesService, i13.Ae2FeedbackService, i14.Ae2AuthStoreService, i15.Ae2LoginStrategy, i16.TranslationService, i17.Store, i18.Ae2SettingsService], { event: [0, "event"], setAutoBooks: [1, "setAutoBooks"], setEventBooks: [2, "setEventBooks"] }, null)], function (_ck, _v) { var currVal_0 = "calc(50% -15px)"; var currVal_1 = "auto"; var currVal_2 = "25%"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _v.context.$implicit; var currVal_4 = _v.parent.parent.context.eventAutoBooks; var currVal_5 = _v.parent.parent.context.eventBooks; _ck(_v, 3, 0, currVal_3, currVal_4, currVal_5); }, null); }
function View_NexEventsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["fxLayout", "row wrap"], ["fxLayout.xs", "column wrap"], ["fxLayoutAlign", "center center"]], null, null, null, null, null)), i1.ɵdid(2, 737280, null, 0, i3.LayoutDirective, [i4.MediaMonitor, i1.ElementRef, i4.StyleUtils], { layout: [0, "layout"], layoutXs: [1, "layoutXs"] }, null), i1.ɵdid(3, 737280, null, 0, i3.LayoutAlignDirective, [i4.MediaMonitor, i1.ElementRef, [6, i3.LayoutDirective], i4.StyleUtils], { align: [0, "align"] }, null), (_l()(), i1.ɵand(16777216, [[1, 2]], null, 1, null, View_NexEventsComponent_5)), i1.ɵdid(5, 802816, null, 0, i19.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = "row wrap"; var currVal_1 = "column wrap"; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = "center center"; _ck(_v, 3, 0, currVal_2); var currVal_3 = _v.parent.context.results; _ck(_v, 5, 0, currVal_3); }, null); }
function View_NexEventsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, [[1, 2]], null, 1, null, View_NexEventsComponent_3)), i1.ɵdid(1, 16384, null, 0, i19.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, [[1, 2]], null, 1, null, View_NexEventsComponent_4)), i1.ɵdid(3, 16384, null, 0, i19.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = ((_v.context.results.length > 0) && _v.parent.context.$implicit.name); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_v.context.results.length > 0); _ck(_v, 3, 0, currVal_1); }, null); }
function View_NexEventsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "ev_ct_b"], ["fxFlex", "calc(50% - 15px)"], ["fxFlex.sm", "auto"], ["fxFlex.xs", "auto"]], null, null, null, null, null)), i1.ɵdid(1, 737280, null, 0, i3.FlexDirective, [i4.MediaMonitor, i1.ElementRef, [3, i3.LayoutDirective], i4.StyleUtils, i4.LAYOUT_CONFIG], { flex: [0, "flex"], flexXs: [1, "flexXs"], flexSm: [2, "flexSm"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 5, "ae2-event-list-container", [["nextEvents", "true"], ["pageSize", "4"], ["sort", "start_time"]], null, null, null, i20.View_Ae2EventListContainerComponent_0, i20.RenderType_Ae2EventListContainerComponent)), i1.ɵprd(6144, null, i21.Ae2ListContainerBase, null, [i22.Ae2EventListContainerComponent]), i1.ɵdid(4, 4833280, null, 1, i22.Ae2EventListContainerComponent, [i9.Ae2EventsService, i10.Ae2EventAutoBooksService, i23.Ae2EventBooksService, i1.ChangeDetectorRef, i17.Store], { useLazyLoading: [0, "useLazyLoading"], useLoadAllPages: [1, "useLoadAllPages"], pageSize: [2, "pageSize"], nextEvents: [3, "nextEvents"], activityList: [4, "activityList"], sort: [5, "sort"] }, null), i1.ɵqud(603979776, 1, { templateRef: 0 }), i1.ɵpad(6, 1), (_l()(), i1.ɵand(0, [[1, 2]], null, 0, null, View_NexEventsComponent_2))], function (_ck, _v) { var currVal_0 = "calc(50% - 15px)"; var currVal_1 = "auto"; var currVal_2 = "auto"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = true; var currVal_4 = false; var currVal_5 = "4"; var currVal_6 = "true"; var currVal_7 = _ck(_v, 6, 0, _v.context.$implicit.id); var currVal_8 = "start_time"; _ck(_v, 4, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }, null); }
export function View_NexEventsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "aside", [["class", "ev"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "section", [["class", "ev_ct"], ["fxLayout", ""], ["fxLayout.sm", "column"], ["fxLayout.xs", "column"], ["fxLayoutAlign", "center center"]], null, null, null, null, null)), i1.ɵdid(2, 737280, null, 0, i3.LayoutDirective, [i4.MediaMonitor, i1.ElementRef, i4.StyleUtils], { layout: [0, "layout"], layoutXs: [1, "layoutXs"], layoutSm: [2, "layoutSm"] }, null), i1.ɵdid(3, 737280, null, 0, i3.LayoutAlignDirective, [i4.MediaMonitor, i1.ElementRef, [6, i3.LayoutDirective], i4.StyleUtils], { align: [0, "align"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NexEventsComponent_1)), i1.ɵdid(5, 802816, null, 0, i19.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; var currVal_1 = "column"; var currVal_2 = "column"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = "center center"; _ck(_v, 3, 0, currVal_3); var currVal_4 = _co.results; _ck(_v, 5, 0, currVal_4); }, null); }
export function View_NexEventsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-nex-events", [], null, null, null, View_NexEventsComponent_0, RenderType_NexEventsComponent)), i1.ɵdid(1, 114688, null, 0, i24.NexEventsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NexEventsComponentNgFactory = i1.ɵccf("app-nex-events", i24.NexEventsComponent, View_NexEventsComponent_Host_0, { results: "results" }, {}, []);
export { NexEventsComponentNgFactory as NexEventsComponentNgFactory };
