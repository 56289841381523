<ae2-carousel-container carouselCode="home" [config]="carouselConfig">
    <ng-template ae2CarouselOverlayContent let-title="title" let-description="description" let-ctaLabel="ctaLabel" let-ctaUrl="ctaUrl">
        <!-- <div class="ae2Carousel__overlay-content-default">
            <h1 class="ae2Carousel__overlay-content-default__title" [innerHTML]="title"></h1>
            <h3 class="ae2Carousel__overlay-content-default__subtitle" [innerHTML]="description"></h3>
            <button class="ae2Carousel__overlay-content-default__button" mat-raised-button [routerLink]="ctaUrl">{{ ctaLabel }}</button>
        </div> -->
    </ng-template>
</ae2-carousel-container>

<app-list-units id="estudio"></app-list-units>

<ae2-activity-list-container showInActivitiesPage="true" active="true">
    <ng-template #activityListTemplate let-results="results">

        <app-activities id="atividades" [results]="results"></app-activities>

        <app-nex-events id="eventos" [results]="results"></app-nex-events>

    </ng-template>
</ae2-activity-list-container>

<!--
<app-package ae2ScrollWatcher='pacote' id="pacote"></app-package>
-->

<app-logos id="conceito"></app-logos>

<app-about id="sobre"></app-about>

<app-trainers id="instrutores"></app-trainers>

<app-map id="mapa"></app-map>

<app-contact id="contato"></app-contact>