import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { AgmCoreModule } from '@agm/core';
import { MapComponent } from './map.component';
import { Ae2UnitListContainerModule } from '@angularecommerce/core/components/unit-list-container';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    Ae2UnitListContainerModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCziz3QhZ5Pb1-MSkkpt89y10jn9lR8BVg'
    })
  ],
  declarations: [MapComponent],
  exports: [MapComponent]
})
export class MapModule { }
