<ae2-article code="sobre">
    <ng-template ae2ArticleContent let-image="image" let-title="title" let-description="description">

        <section class="l" fxLayout="column" fxLayoutAlign="center center">
            <ng-container *ngIf="title">
                <h2 class="l_t">{{title}}</h2>
            </ng-container>
            <ng-container *ngIf="description">
                <div class="l_d" [innerHTML]="description"></div>
            </ng-container>
            <ng-container *ngIf="image">
                <img class="l_i" src="{{image | ae2Thumbor:'1340x'}}">
            </ng-container>
        </section>

    </ng-template>
</ae2-article>