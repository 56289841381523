import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { LogosComponent } from './logos.component';
import { Ae2ArticleModule } from '@angularecommerce/core/components/article';
import { Ae2ThumborModule } from '@angularecommerce/core/pipes/thumbor';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    Ae2ArticleModule,
    Ae2ThumborModule
  ],
  declarations: [LogosComponent],
  exports: [LogosComponent]
})
export class LogosModule { }
