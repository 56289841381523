import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeaderModule } from './shared/components/header/header.module';
import { FooterModule } from './shared/components/footer/footer.module';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { Ae2CoreModule } from '@angularecommerce/core/modules/core';
import { MenuInterface, Ae2MyAccountGlobalConfig, AE2_MY_ACCOUNT_CONFIG_TOKEN } from '@angularecommerce/core/components/my-account';
import { Ae2AuthVerificationInitializerModule } from '@angularecommerce/core/modules/auth-verification-initializer';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { Ae2AuthStoreModule } from '@angularecommerce/core/stores/auth';
import { Ae2HeaderStoreModule } from '@angularecommerce/core/stores/header';
import { Ae2UnitSelectorStoreModule } from '@angularecommerce/core/stores/unit-selector';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';

registerLocaleData(localePt, 'pt-BR');

export class MyAccountCustomConfig extends Ae2MyAccountGlobalConfig {
  // Menus
  menu: MenuInterface[] = [];

  constructor() {
    super();

    this.showFTP = false;

    this.menu.push({ name: 'Meus dados', link: 'meus-dados', icon: 'account_circle' });
    this.menu.push({ name: 'Alterar minha senha', link: 'alterar-senha', icon: 'lock' });
    this.menu.push({ name: 'Meus endereços', link: 'meus-enderecos', icon: 'room' });
    this.menu.push({ name: 'Cartões de Crédito', link: 'metodos-pagamento', icon: 'credit_card' });
    this.menu.push({ name: 'Meus pedidos', link: 'meus-pedidos', icon: 'bookmark_border' });
    this.menu.push({ name: 'Minhas assinaturas', link: 'minhas-assinaturas', icon: 'bookmark' });
    this.menu.push({ name: 'Meus eventos', link: 'meus-eventos', icon: 'event' });
    this.menu.push({ name: 'Horário Fixo', link: 'meus-eventos-automaticos', icon: 'event' });
    this.menu.push({ name: 'Meus créditos', link: 'meus-creditos', icon: 'account_balance' });
    this.menu.push({ name: 'Compartilhamento de Créditos', link: 'compartilhar-creditos', icon: 'share' });
    this.menu.push({ name: 'Dados de performance', link: 'dados-de-performance', icon: 'timeline' });
  }
}


export const APP_INITIALIZERS = [
  Ae2AuthVerificationInitializerModule
];

export const APP_STORE_MODULES = [
  StoreModule.forRoot({}),
  EffectsModule.forRoot([]),

  /**
   * Remova esse módulo se não estiver usando sistema de autenticação
   */
  Ae2AuthStoreModule,

  /**
   * Remova esses módulos se não estiver usando header-widget no site
   */
  Ae2HeaderStoreModule,
  Ae2UnitSelectorStoreModule
];

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpModule,
    HttpClientModule,

    AppRoutingModule,

    Ae2CoreModule,
    APP_STORE_MODULES,
    APP_INITIALIZERS,

    CommonModule,
    HeaderModule,
    FooterModule,
    RouterModule,
  ],
  providers: [
    { provide: AE2_MY_ACCOUNT_CONFIG_TOKEN, useClass: MyAccountCustomConfig },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
