import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-trainers',
  templateUrl: './trainers.component.html',
  styleUrls: ['./trainers.component.sass']
})
export class TrainersComponent implements OnInit {

  instrutor: any;
  instrutorList: any;
  constructor(private sanitizer: DomSanitizer) {
  }
  ngOnInit(): void {
    this.creatOneInstructor();
  }
  setInstructor(instructor: any): void {
    this.instrutor = instructor;
  }
  creatListInstructor(instructors: any): void {
    this.instrutorList = instructors;
    // if (!this.instrutor && this.instrutorList.length >= 1) {
    //     this.instrutor = this.instrutorList[0];
    // }
  }
  creatOneInstructor(): void {
    setTimeout(() => {
      this.instrutor = this.instrutor ? this.instrutor : this.instrutorList ? this.instrutorList[0] : this.instrutor;
      const instructor = !this.instrutor ? this.creatOneInstructor() : '';
    }, 1000);
  }
  replace(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
