import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { NexEventsComponent } from './nex-events.component';
import { Ae2EventListContainerModule } from '@angularecommerce/core/components/event-list-container';
import { Ae2EventWidgetModule } from '@angularecommerce/core/components/event-widget';
@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    Ae2EventWidgetModule,
    Ae2EventListContainerModule,
  ],
  declarations: [NexEventsComponent],
  exports: [NexEventsComponent]
})
export class NexEventsModule { }
