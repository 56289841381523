import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { Ae2CarouselContainerModule } from '@angularecommerce/core/components/carousel-container';
import { ListUnitsModule } from '../../shared/components/list-units/list-units.module';
import { ActivitiesModule } from '../../shared/components/activities/activities.module';
import { TrainersModule } from '../../shared/components/trainers/trainers.module';
import { ContactModule } from '../../shared/components/contact/contact.module';
import { Ae2ScrollWatcherModule } from '../../shared/directives/scrollwatcher/scrollwatcher.module';
import { NexEventsModule } from '../../shared/components/nex-events/nex-events.module';
import { LogosModule } from '../../shared/components/logos/logos.module';
import { MapModule } from '../../shared/components/map/map.module';
import { AboutModule } from '../../shared/components/about/about.module';
import { Ae2ActivityListContainerModule } from '@angularecommerce/core/components/activity-list-container';

@NgModule({
  imports: [
    CommonModule,
    HomeRoutingModule,
    Ae2CarouselContainerModule,
    ListUnitsModule,
    ActivitiesModule,
    TrainersModule,
    ContactModule,
    Ae2ScrollWatcherModule,
    NexEventsModule,
    Ae2ActivityListContainerModule,
    MapModule,
    LogosModule,
    AboutModule
  ],
  declarations: [HomeComponent]
})
export class HomeModule { }
