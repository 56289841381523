<ae2-unit-list-container>
    <ng-template #unitListTemplate let-results="results">
        <ng-container *ngIf="results.length > 0">
            <aside class="ct">
                <div *ngFor="let item of results; let i = index">
                    <ng-container *ngIf="item.latitude && item.longitude">
                        <div class="map">
                            <div class="boxMap" fxLayout>
                                <div class="boxMap_pin" fxFlex="20%"></div>
                                <div class="boxMap_box" fxFlex="80%">
                                    <div class="boxMap_box_title">Onde estamos</div>
                                    <div class="boxMap_box_address" [innerHTML]="item.address"></div>
                                </div>
                            </div>
                            <agm-map [latitude]="item.latitude" [longitude]="item.longitude" [zoom]="zoom" [styles]="customStyle"
                                [scrollwheel]="false">
                                <agm-marker [latitude]="item.latitude" [longitude]="item.longitude">
                                </agm-marker>
                            </agm-map>
                        </div>
                    </ng-container>

                </div>
            </aside>
        </ng-container>
    </ng-template>
</ae2-unit-list-container>