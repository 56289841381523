import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-logos',
    templateUrl: './logos.component.html',
    styleUrls: ['./logos.component.sass']
})

export class LogosComponent implements OnInit {
    ngOnInit(): void {
    }
}
