import { Fields } from '@angularecommerce/core/components/sign-up';
import {
    Ae2HeaderWidgetConfig, Ae2HeaderMenu, Ae2HeaderSubmenu,
    Ae2HeaderLinkToSession, Ae2HeaderLinkToPage
} from '@angularecommerce/core/components/header-widget';
import {
    Ae2SignUpStepSignup,
    Ae2SignUpStepAddress,
    Ae2SignUpStepFitness,
    Ae2SignUpStepProfile,
    Ae2SignUpFieldValidator,
    Ae2SignupFieldTypes,
    Ae2SignUpField,
    Ae2SignUpStepperConfig
} from '@angularecommerce/core/components/sign-up-steps';
import { OnInit, Component } from '@angular/core';
import { ae2SamePasswordValidator } from '@angularecommerce/core/functions/same-password-validator';

class CustomAe2SignUpStepSignup extends Ae2SignUpStepSignup {
    constructor() {
        super();

        this.fields = [
            new Ae2SignUpField({
                name: 'email',
                label: 'Email',
                validators: new Ae2SignUpFieldValidator({ required: true, validEmail: true, duplicateEmail: true }),
                row: 1,
                size: '48.5%'
            }),
            new Ae2SignUpField({
                name: 'password',
                label: 'Senha',
                validators: new Ae2SignUpFieldValidator({ type: Ae2SignupFieldTypes.PASSWORD, required: true, minlength: 6 }),
                row: 2,
                size: '48.5%'
            }),
            new Ae2SignUpField({
                name: 'confirmPassword',
                label: 'Confirmar senha',
                validators: new Ae2SignUpFieldValidator({ type: Ae2SignupFieldTypes.PASSWORD, required: true, minlength: 6 }),
                row: 2,
                size: '48.5%'
            })
        ];

        this.syncValidators = ae2SamePasswordValidator('password', 'confirmPassword');
    }
}

export const CustomAe2SignupConfig = new Ae2SignUpStepperConfig([
    new CustomAe2SignUpStepSignup(),
    new Ae2SignUpStepProfile(),
    new Ae2SignUpStepAddress(),
    new Ae2SignUpStepFitness()
]);

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.sass']
})

export class HeaderComponent implements OnInit {

    headerConfig: Ae2HeaderWidgetConfig = new Ae2HeaderWidgetConfig();
    signupConfig: Ae2SignUpStepperConfig;
    signupFields: Fields;

    constructor() {
    }

    ngOnInit(): void {
        this.createSignupConfig();

        this.headerConfig = new Ae2HeaderWidgetConfig();
        this.headerConfig.menuItems = new Ae2HeaderMenu();
        this.headerConfig.menuItems.items = [
            new Ae2HeaderSubmenu('Estudio', [
                new Ae2HeaderLinkToSession('Conceito', 'estudio'),
                new Ae2HeaderLinkToSession('Atividades', 'atividades'),
                new Ae2HeaderLinkToSession('Sobre', 'sobre'),
                new Ae2HeaderLinkToSession('Instrutores', 'instrutores'),
                new Ae2HeaderLinkToSession('Contato', 'contato')
            ]),
            new Ae2HeaderLinkToPage('Pacotes', '/pacotes'),
            new Ae2HeaderLinkToPage('Agenda', '/agenda')
        ];

        this.headerConfig.backgroundColor = '#ff0000';
    }

    createSignupConfig(): void {
        this.signupConfig = CustomAe2SignupConfig;
    }
}
