/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./about.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "@angular/common";
import * as i5 from "@angularecommerce/core/pipes/thumbor/thumbor.pipe";
import * as i6 from "@angularecommerce/core/services/settings/settings.service";
import * as i7 from "../../../../../node_modules/@angularecommerce/core/components/article/article.component.ngfactory";
import * as i8 from "@angularecommerce/core/components/article/article.component";
import * as i9 from "@angularecommerce/core/services/article/article.service";
import * as i10 from "./about.component";
var styles_AboutComponent = [i0.styles];
var RenderType_AboutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AboutComponent, data: {} });
export { RenderType_AboutComponent as RenderType_AboutComponent };
function View_AboutComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [["class", "l_t"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.title; _ck(_v, 2, 0, currVal_0); }); }
function View_AboutComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "l_d"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.description; _ck(_v, 1, 0, currVal_0); }); }
function View_AboutComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "img", [["class", "l_i"]], [[8, "src", 4]], null, null, null, null)), i1.ɵppd(2, 2)], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 0), _v.parent.context.image, "1340x")), ""); _ck(_v, 1, 0, currVal_0); }); }
function View_AboutComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "section", [["class", "l"], ["fxLayout", "column"], ["fxLayoutAlign", "center center"]], null, null, null, null, null)), i1.ɵdid(1, 737280, null, 0, i2.LayoutDirective, [i3.MediaMonitor, i1.ElementRef, i3.StyleUtils], { layout: [0, "layout"] }, null), i1.ɵdid(2, 737280, null, 0, i2.LayoutAlignDirective, [i3.MediaMonitor, i1.ElementRef, [6, i2.LayoutDirective], i3.StyleUtils], { align: [0, "align"] }, null), (_l()(), i1.ɵand(16777216, [[1, 2]], null, 1, null, View_AboutComponent_2)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, [[1, 2]], null, 1, null, View_AboutComponent_3)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, [[1, 2]], null, 1, null, View_AboutComponent_4)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = "column"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "center center"; _ck(_v, 2, 0, currVal_1); var currVal_2 = _v.context.title; _ck(_v, 4, 0, currVal_2); var currVal_3 = _v.context.description; _ck(_v, 6, 0, currVal_3); var currVal_4 = _v.context.image; _ck(_v, 8, 0, currVal_4); }, null); }
export function View_AboutComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i5.Ae2ThumborPipe, [i6.Ae2SettingsService]), (_l()(), i1.ɵeld(1, 0, null, null, 3, "ae2-article", [["code", "sobre"]], null, null, null, i7.View_Ae2ArticleComponent_0, i7.RenderType_Ae2ArticleComponent)), i1.ɵdid(2, 114688, null, 1, i8.Ae2ArticleComponent, [i9.Ae2ArticleService, i1.ChangeDetectorRef], { code: [0, "code"] }, null), i1.ɵqud(603979776, 1, { templateRef: 0 }), (_l()(), i1.ɵand(0, [[1, 2]], null, 0, null, View_AboutComponent_1))], function (_ck, _v) { var currVal_0 = "sobre"; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_AboutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-about", [], null, null, null, View_AboutComponent_0, RenderType_AboutComponent)), i1.ɵdid(1, 114688, null, 0, i10.AboutComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AboutComponentNgFactory = i1.ɵccf("app-about", i10.AboutComponent, View_AboutComponent_Host_0, {}, {}, []);
export { AboutComponentNgFactory as AboutComponentNgFactory };
