/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./activities.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "@angular/common";
import * as i5 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i6 from "@angular/router";
import * as i7 from "@angular/material/button";
import * as i8 from "@angular/cdk/platform";
import * as i9 from "@angular/cdk/a11y";
import * as i10 from "@angular/platform-browser/animations";
import * as i11 from "@angularecommerce/core/pipes/thumbor/thumbor.pipe";
import * as i12 from "@angularecommerce/core/services/settings/settings.service";
import * as i13 from "./activities.component";
import * as i14 from "@angular/platform-browser";
var styles_ActivitiesComponent = [i0.styles];
var RenderType_ActivitiesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ActivitiesComponent, data: {} });
export { RenderType_ActivitiesComponent as RenderType_ActivitiesComponent };
function View_ActivitiesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["fxFlex", "50%"]], null, null, null, null, null)), i1.ɵdid(1, 737280, null, 0, i2.FlexDirective, [i3.MediaMonitor, i1.ElementRef, [3, i2.LayoutDirective], i3.StyleUtils, i3.LAYOUT_CONFIG], { flex: [0, "flex"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 13, "div", [["class", "box"]], null, null, null, null, null)), i1.ɵdid(3, 278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "img", [["class", "box_img"]], [[8, "src", 4]], null, null, null, null)), i1.ɵppd(5, 2), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "box_img_c"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 8, "div", [["fxLayout", "column"], ["fxLayoutAlign.xs", "baselne center"]], null, null, null, null, null)), i1.ɵdid(8, 737280, null, 0, i2.LayoutDirective, [i3.MediaMonitor, i1.ElementRef, i3.StyleUtils], { layout: [0, "layout"] }, null), i1.ɵdid(9, 737280, null, 0, i2.LayoutAlignDirective, [i3.MediaMonitor, i1.ElementRef, [6, i2.LayoutDirective], i3.StyleUtils], { align: [0, "align"], alignXs: [1, "alignXs"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 0, "div", [["class", "box_desc"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 4, "button", [["class", "box_button"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(12, 16384, null, 0, i6.RouterLink, [i6.Router, i6.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(13, 4), i1.ɵdid(14, 180224, null, 0, i7.MatButton, [i1.ElementRef, i8.Platform, i9.FocusMonitor, [2, i10.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["SAIBA MAIS"]))], function (_ck, _v) { var currVal_0 = "50%"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "box"; var currVal_2 = _v.context.$implicit.cssClasses; _ck(_v, 3, 0, currVal_1, currVal_2); var currVal_4 = "column"; _ck(_v, 8, 0, currVal_4); var currVal_5 = i1.ɵinlineInterpolate(1, "baseline ", ((_v.context.$implicit.cssClasses == "activity-crossfit") ? "flex-end" : "center"), ""); var currVal_6 = "baselne center"; _ck(_v, 9, 0, currVal_5, currVal_6); var currVal_10 = _ck(_v, 13, 0, "/", "atividades", _v.context.$implicit.slug, _v.context.$implicit.id); _ck(_v, 12, 0, currVal_10); }, function (_ck, _v) { var currVal_3 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent.parent, 0), _v.context.$implicit.mediumImage, "700x")), ""); _ck(_v, 4, 0, currVal_3); var currVal_7 = _v.context.$implicit.description; _ck(_v, 10, 0, currVal_7); var currVal_8 = (i1.ɵnov(_v, 14).disabled || null); var currVal_9 = (i1.ɵnov(_v, 14)._animationMode === "NoopAnimations"); _ck(_v, 11, 0, currVal_8, currVal_9); }); }
function View_ActivitiesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "aside", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "section", [["fxLayout", ""], ["fxLayout.xs", "column"], ["fxLayoutAlign", "center center"]], null, null, null, null, null)), i1.ɵdid(3, 737280, null, 0, i2.LayoutDirective, [i3.MediaMonitor, i1.ElementRef, i3.StyleUtils], { layout: [0, "layout"], layoutXs: [1, "layoutXs"] }, null), i1.ɵdid(4, 737280, null, 0, i2.LayoutAlignDirective, [i3.MediaMonitor, i1.ElementRef, [6, i2.LayoutDirective], i3.StyleUtils], { align: [0, "align"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActivitiesComponent_2)), i1.ɵdid(6, 802816, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; var currVal_1 = "column"; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = "center center"; _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.results; _ck(_v, 6, 0, currVal_3); }, null); }
export function View_ActivitiesComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i11.Ae2ThumborPipe, [i12.Ae2SettingsService]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActivitiesComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.results && (_co.results.length > 0)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ActivitiesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-activities", [], null, null, null, View_ActivitiesComponent_0, RenderType_ActivitiesComponent)), i1.ɵdid(1, 114688, null, 0, i13.ActivitiesComponent, [i14.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ActivitiesComponentNgFactory = i1.ɵccf("app-activities", i13.ActivitiesComponent, View_ActivitiesComponent_Host_0, { results: "results" }, {}, []);
export { ActivitiesComponentNgFactory as ActivitiesComponentNgFactory };
