<ng-container *ngIf="results && results.length > 0">
    <aside>
        <!-- <section fxLayout fxLayout.xs="column"> -->
        <section fxLayout fxLayout.xs="column" fxLayoutAlign="center center">
            <div fxFlex="50%" *ngFor="let activity of results">
                <div class="box" [ngClass]="activity.cssClasses">
                    <img class="box_img" src="{{activity.mediumImage | ae2Thumbor:'700x'}}">
                    <div class="box_img_c"></div>
                    <!-- <div fxLayout="column" fxLayoutAlign.xs="baselne center" fxLayoutAlign="baseline {{ activity.cssClasses == 'activity-crossfit' ? 'flex-end' : 'flex-start'}}"> -->
                    <div fxLayout="column" fxLayoutAlign.xs="baselne center" fxLayoutAlign="baseline {{ activity.cssClasses == 'activity-crossfit' ? 'flex-end' : 'center'}}">
                        <div class="box_desc" [innerHTML]="activity.description"></div>
                        <button [routerLink]="['/','atividades', activity.slug, activity.id]" class="box_button" mat-raised-button>SAIBA MAIS</button>
                    </div>

                </div>
            </div>
        </section>
    </aside>

</ng-container>
