import { NgModule } from '@angular/core';
import { HeaderComponent } from './header.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { Ae2HeaderWidgetModule } from '@angularecommerce/core/components/header-widget';
import { Ae2RouterClassesConfig, AE2_ROUTER_CLASSES_CONFIG_TOKEN, Ae2RouterClassesModule } from '@angularecommerce/core/directives/router-classes';
import { MatIconModule, MatDividerModule } from '@angular/material';

export class CustomRouterClassesConfig extends Ae2RouterClassesConfig {
  cssClasses: string[] = ['pagesCustom'];
  fragments: string[] = [
    'login',
    'categoria',
    'minha-conta',
    'checkout',
    'agenda',
    'evento',
    'atividades',
    'instrutor',
    'clientes',
    'faq',
    'termos',
    'politica',
    'depoimentos',
    'blog',
    'lp',
    'obrigado'
  ];
}
@NgModule({
  imports: [
    FlexLayoutModule,
    CommonModule,
    RouterModule,
    Ae2RouterClassesModule,
    Ae2HeaderWidgetModule,
    MatIconModule,
    MatDividerModule
  ],
  declarations: [HeaderComponent],
  exports: [HeaderComponent],
  providers: [
    { provide: AE2_ROUTER_CLASSES_CONFIG_TOKEN, useClass: CustomRouterClassesConfig }
  ]
})
export class HeaderModule { }
