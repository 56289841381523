/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./logos.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "@angular/common";
import * as i5 from "@angularecommerce/core/pipes/thumbor/thumbor.pipe";
import * as i6 from "@angularecommerce/core/services/settings/settings.service";
import * as i7 from "../../../../../node_modules/@angularecommerce/core/components/article/article.component.ngfactory";
import * as i8 from "@angularecommerce/core/components/article/article.component";
import * as i9 from "@angularecommerce/core/services/article/article.service";
import * as i10 from "./logos.component";
var styles_LogosComponent = [i0.styles];
var RenderType_LogosComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LogosComponent, data: {} });
export { RenderType_LogosComponent as RenderType_LogosComponent };
function View_LogosComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "section", [["class", "l"], ["fxLayoutAlign", "center center"]], null, null, null, null, null)), i1.ɵdid(2, 737280, null, 0, i2.LayoutAlignDirective, [i3.MediaMonitor, i1.ElementRef, [8, null], i3.StyleUtils], { align: [0, "align"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "img", [["class", "a_l"]], [[8, "src", 4]], null, null, null, null)), i1.ɵppd(4, 2)], function (_ck, _v) { var currVal_0 = "center center"; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent, 0), _v.parent.context.image, "670x")), ""); _ck(_v, 3, 0, currVal_1); }); }
function View_LogosComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, [[1, 2]], null, 1, null, View_LogosComponent_2)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.image; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LogosComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i5.Ae2ThumborPipe, [i6.Ae2SettingsService]), (_l()(), i1.ɵeld(1, 0, null, null, 3, "ae2-article", [["code", "logos"]], null, null, null, i7.View_Ae2ArticleComponent_0, i7.RenderType_Ae2ArticleComponent)), i1.ɵdid(2, 114688, null, 1, i8.Ae2ArticleComponent, [i9.Ae2ArticleService, i1.ChangeDetectorRef], { code: [0, "code"] }, null), i1.ɵqud(603979776, 1, { templateRef: 0 }), (_l()(), i1.ɵand(0, [[1, 2]], null, 0, null, View_LogosComponent_1))], function (_ck, _v) { var currVal_0 = "logos"; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_LogosComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-logos", [], null, null, null, View_LogosComponent_0, RenderType_LogosComponent)), i1.ɵdid(1, 114688, null, 0, i10.LogosComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LogosComponentNgFactory = i1.ɵccf("app-logos", i10.LogosComponent, View_LogosComponent_Host_0, {}, {}, []);
export { LogosComponentNgFactory as LogosComponentNgFactory };
