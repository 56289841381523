var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Ae2HeaderWidgetConfig, Ae2HeaderMenu, Ae2HeaderSubmenu, Ae2HeaderLinkToSession, Ae2HeaderLinkToPage } from '@angularecommerce/core/components/header-widget';
import { Ae2SignUpStepSignup, Ae2SignUpStepAddress, Ae2SignUpStepFitness, Ae2SignUpStepProfile, Ae2SignUpFieldValidator, Ae2SignupFieldTypes, Ae2SignUpField, Ae2SignUpStepperConfig } from '@angularecommerce/core/components/sign-up-steps';
import { OnInit } from '@angular/core';
import { ae2SamePasswordValidator } from '@angularecommerce/core/functions/same-password-validator';
var CustomAe2SignUpStepSignup = /** @class */ (function (_super) {
    __extends(CustomAe2SignUpStepSignup, _super);
    function CustomAe2SignUpStepSignup() {
        var _this = _super.call(this) || this;
        _this.fields = [
            new Ae2SignUpField({
                name: 'email',
                label: 'Email',
                validators: new Ae2SignUpFieldValidator({ required: true, validEmail: true, duplicateEmail: true }),
                row: 1,
                size: '48.5%'
            }),
            new Ae2SignUpField({
                name: 'password',
                label: 'Senha',
                validators: new Ae2SignUpFieldValidator({ type: Ae2SignupFieldTypes.PASSWORD, required: true, minlength: 6 }),
                row: 2,
                size: '48.5%'
            }),
            new Ae2SignUpField({
                name: 'confirmPassword',
                label: 'Confirmar senha',
                validators: new Ae2SignUpFieldValidator({ type: Ae2SignupFieldTypes.PASSWORD, required: true, minlength: 6 }),
                row: 2,
                size: '48.5%'
            })
        ];
        _this.syncValidators = ae2SamePasswordValidator('password', 'confirmPassword');
        return _this;
    }
    return CustomAe2SignUpStepSignup;
}(Ae2SignUpStepSignup));
export var CustomAe2SignupConfig = new Ae2SignUpStepperConfig([
    new CustomAe2SignUpStepSignup(),
    new Ae2SignUpStepProfile(),
    new Ae2SignUpStepAddress(),
    new Ae2SignUpStepFitness()
]);
var HeaderComponent = /** @class */ (function () {
    function HeaderComponent() {
        this.headerConfig = new Ae2HeaderWidgetConfig();
    }
    HeaderComponent.prototype.ngOnInit = function () {
        this.createSignupConfig();
        this.headerConfig = new Ae2HeaderWidgetConfig();
        this.headerConfig.menuItems = new Ae2HeaderMenu();
        this.headerConfig.menuItems.items = [
            new Ae2HeaderSubmenu('Estudio', [
                new Ae2HeaderLinkToSession('Conceito', 'estudio'),
                new Ae2HeaderLinkToSession('Atividades', 'atividades'),
                new Ae2HeaderLinkToSession('Sobre', 'sobre'),
                new Ae2HeaderLinkToSession('Instrutores', 'instrutores'),
                new Ae2HeaderLinkToSession('Contato', 'contato')
            ]),
            new Ae2HeaderLinkToPage('Pacotes', '/pacotes'),
            new Ae2HeaderLinkToPage('Agenda', '/agenda')
        ];
        this.headerConfig.backgroundColor = '#ff0000';
    };
    HeaderComponent.prototype.createSignupConfig = function () {
        this.signupConfig = CustomAe2SignupConfig;
    };
    return HeaderComponent;
}());
export { HeaderComponent };
