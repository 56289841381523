import { NgModule } from '@angular/core';
import { Ae2ScrollWatcherDirective } from './scrollwatcher.directive';
import { Ae2ScrollWatcherService } from './scroll-watcher.service';

@NgModule({
  declarations: [Ae2ScrollWatcherDirective],
  exports: [Ae2ScrollWatcherDirective],
  providers: [Ae2ScrollWatcherService]
})
export class Ae2ScrollWatcherModule { }
