import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Ae2ActivityModel } from '@angularecommerce/core/services/activities';

@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.sass']
})
export class ActivitiesComponent implements OnInit {
  @Input() results: Ae2ActivityModel[];
  constructor(private sanitizer: DomSanitizer) {
  }
  ngOnInit(): void {
  }
  replace(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
