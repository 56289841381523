import { Component, OnInit, Input } from '@angular/core';
import { Ae2ActivityModel } from '@angularecommerce/core/services/activities';

@Component({
    selector: 'app-nex-events',
    templateUrl: './nex-events.component.html',
    styleUrls: ['./nex-events.component.sass']
})

export class NexEventsComponent implements OnInit {
    @Input() results: Ae2ActivityModel[];
    ngOnInit(): void {
    }
}
